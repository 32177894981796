import React from 'react'

const BecheNutrients = () => {
	return (
		<div>
			<img
				src='/images/products/beche/nutrients/beche-nutrients.jpg'
				alt='nutrients'
				width='752'
				height='600'
				style={{ display: 'block', margin: '0 auto' }}
			/>
		</div>
	)
}

export default BecheNutrients
